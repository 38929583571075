@import "../../../constants/mixins/mixins.scss";

.background_img {
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.img {
  display: block;
}
