@import './constants/mixins/mixins.scss';
@import './constants/font-families.scss';

/* focus-visible polyfill */
/*
  This will hide the focus indicator if the element receives focus via the mouse,
  but it will still show up on keyboard focus.
*/
.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

/*
  Optionally: Define a strong focus indicator for keyboard focus.
  If you choose to skip this step then the browser's default focus
  indicator will be displayed instead.
*/
.js-focus-visible .focus-visible {
  outline: none;
  box-shadow: 0 0 0 1px $robin-s-egg;
  border-color: $robin-s-egg;
}

input:focus,
textarea:focus,
.css-qqooh5-control:focus {
  outline: none;
  box-shadow: 0 0 0 1px $robin-s-egg;
  border-color: $robin-s-egg;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  font-weight: normal;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

video {
  object-fit: cover;
}

.app {
  width: 100%;
}

.view {
  @include header-height;
  // for navigating to a blank page, the footer doesn't bunch up to the top
  display: flex;
  align-items: center;
  min-height: calc(100vh - 457px);

  @include s-desktop {
    min-height: calc(100vh - 233px);
  }

  > div {
    flex: auto;
  }
}

.hidden_page_h1 {
  line-height: 0;
  opacity: 0;
}

.page-transition--container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;

  &.in {
    z-index: 10;

    .page-transition {
      left: 100%;
      transition: left 1.7s $ease-page-transition;

      &::after {
        left: 100%;
        transition: left 1.7s $ease-page-transition 0.15s;
      }
    }
  }
}

.page-transition {
  position: absolute;
  width: 100%;
  height: 50%;
  top: 0;
  left: -100%;
  background-color: $white;
  z-index: 10;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    left: -100%;
    background-color: $white;
    z-index: 10;
  }
}

.fade-enter {
  opacity: 0;
  position: absolute;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in 700ms;
}

.fade-exit {
  opacity: 1;
  // position: absolute;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in 700ms;
}
