@font-face {
  font-family: 'ProximaNova';
  src: url('../assets/fonts/proximanova-regular.woff2') format('woff2'),
    url('../assets/fonts/proximanova-regular.woff') format('woff');
}

@font-face {
  font-family: 'ProximaNova-Black';
  src: url('../assets/fonts/proximanova-black.woff2') format('woff2'),
    url('../assets/fonts/proximanova-black.woff') format('woff');
}

@font-face {
  font-family: 'ProximaNova-Medium';
  src: url('../assets/fonts/proximanova-medium.woff2') format('woff2'),
    url('../assets/fonts/proximanova-medium.woff') format('woff');
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../assets/fonts/proximanova-bold.woff2') format('woff2'),
    url('../assets/fonts/proximanova-bold.woff') format('woff');
}

@font-face {
  font-family: 'ProximaNova-SemiBold';
  src: url('../assets/fonts/proximanova-sbold.woff2') format('woff2'),
    url('../assets/fonts/proximanova-sbold.woff') format('woff');
}
